import sortBy from 'lodash/sortBy';
import React from 'react';

export function useServiceOptions() {
  return React.useMemo(() => {
    return sortBy(
      [
        { value: 'pre-pago', label: 'Pré-Pago' },
        { value: 'controle', label: 'Controle' },
        { value: 'pos-pago-individual', label: 'Pós-pago individual' },
        { value: 'pos-pago-familia', label: 'Pós-pago família' },
        { value: 'live', label: 'Live' },
        { value: 'fixo', label: 'Fixo' },
        { value: 'wttx', label: 'WTTX' },
        { value: 'corporativo', label: 'Corporativo' },
        { value: 'aparelho', label: 'Aparelho' }
      ],
      'label'
    );
  }, []);
}
